<template>
    <vs-card>
        <template slot="header">
            <div class="flex justify-end w-full md:w-auto">
              <vs-button @click="redirect()" class="w-full md:w-auto">Create New Consent Form</vs-button>
            </div>
        </template>
        
        <vs-table :max-items="dataTableParams.limit" pagination :data="forms" class="consentTable">
            <template slot="header">
                <vs-select placeholder="10" autocomplete v-model="dataTableParams.limit" class="w-2/12 md:mb-2">
                    <vs-select-item
                    :key="index"
                    :value="item"
                    :text="item"
                    v-for="(item,index) in limitOptions"
                    />
                </vs-select>
            </template>
            <template slot="thead">
                <vs-th>Name</vs-th>
                <vs-th>Active</vs-th>
                <vs-th>Actions</vs-th>
            </template>
            <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
                    <vs-td data-label="Name" :data="data[indextr].name">
                        {{data[indextr].name}}
                    </vs-td>

                    <vs-td data-label="Active" :data="data[indextr].active">
                        {{isActive(data[indextr].status)}}
                    </vs-td>

                    <vs-td data-label="Action" :data="data[indextr]._id">
                        <vs-button
                            type="border"
                            size="small"
                            icon-pack="feather"
                            @click="redirect(data[indextr]._id)"
                            :icon="defaultConsentForm(data[indextr].isDefault)"
                            class="mr-2"
                        ></vs-button>
                        <vs-button
                            v-if="isDefaultAndOrgOwner(data[indextr].isDefault, data[indextr].userId || null)"
                            type="border"
                            size="small"
                            icon-pack="feather"
                            @click="confirmDeleteRecord(data[indextr]._id)"
                            icon="icon-trash"
                            class="mr-2"
                            color="danger"
                        ></vs-button>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-popup :active.sync="popUpDelete" title="Delete Consent Form" >
              <p align="center">Are you sure you want to delete this Consent form?</p>
              <br />
              <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" vs-mt="6">
                <vs-col vs-type="flex" vs-align="left" vs-w="6">
                  <div class="w-full m-5" align="right">
                    <vs-button
                      size="lg"
                      color="primary"
                      @click="deleteDetailHandler"
                    >Yes</vs-button>
                  </div>
                </vs-col>
                <vs-col vs-type="flex" vs-align="left" vs-w="6">
                  <div class="w-full m-5">
                    <vs-button size="lg" color="danger" @click="popUpDelete=false">Cancel</vs-button>
                  </div>
                </vs-col>
              </vs-row>
            </vs-popup>
    </vs-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {},
  data:() => ({
    dataTableParams: {
      dir: "asc",
      page: 1,
      limit: 25
    },
    limitOptions: [5, 25, 50, 100],
    popUpDelete: false,
    popupConsentData: {
      id: ''
    }
  }),
  methods: {
    ...mapActions("consentForms", [
      "createDefaultConsentForms", "fetchConsentForms", "deleteConsentForm"
    ]),
    defaultConsentForm(isDefault){
      if(isDefault) return "icon-eye"
      return "icon-edit"
    },
      
    confirmDeleteRecord(id) {
      this.popupConsentData.id = id;
      this.popUpDelete=true;
    },
    redirect(id = null) {
      const role = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`)
      const path = role === 'Org Owner' ? 'org-owner' : 'nurse'
      const mode = id ? `${id}/details` : "create"

      this.$router.push(`/${path}/consent-forms/${mode}`)
    },
    isActive(isActive) {
      return isActive ? 'Active' : 'Inactive';
    },
    async createDefaultAndFetch() {
      const org_id = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)

      const payload = {};

      if (org_id) payload['orgId'] = org_id

      // await this.createDefaultConsentForms(payload)
      await this.fetchConsentForms(payload)
      
    },
    async deleteDetailHandler() {
        if (!this.popUpDelete) return

        await this.deleteConsentForm(this.popupConsentData.id)
          .then(res => {
            this.popUpDelete=false;
            this.createDefaultAndFetch();
            this.$vs.notify({
              title: "Success",
              text: "Consent Form Removed successfully.",
              color: "success"
            });
          })
          .catch(err => {
            this.popUpDelete = false;
            this.$vs.notify({
              title: err.data.title,
              text: err.data.message,
              color: "danger"
            });
          })
    },
    isDefaultAndOrgOwner(isDefault, userId) {
      const role = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`)
      return (role === 'Org Owner' && !isDefault) || (!isDefault && userId === this.$store.state.AppActiveUser.id);
    }
  },
  computed: {
    ...mapState("consentForms", ["forms"])
  },
  created() {
    this.createDefaultAndFetch()
  }
}
</script>

<style>
.vs-button {
  display: inline-block;
}
.consentTable td:first-child{
  white-space: normal;
}

/* Responsive <table> */
@media screen and (max-width: 600px) {
  .consentTable .vs-table--header {
      margin-bottom: 1rem;
  }
  .consentTable .vs-table--tbody-table {
      min-width: unset;
  }
  .consentTable .vs-table--thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    display: none !important;
  }
  .consentTable .vs-table--tr {
    display: block;
    margin-bottom: .625em;
  }
  .consentTable .vs-table--td {
    border-bottom: 1px solid #eee;
    display: block;
    font-size: 1rem;
    text-align: right;
  }
  .consentTable .vs-table--td:first-child{
    min-width: 230px;
  }
  .consentTable .vs-table--td::before {
    content: attr(data-label);
    float: left;
    font-weight: 500;
    font-size: 13px;
  }
  .consentTable .vs-table--td:last-child {
    border-bottom: 0;
  }
}
</style>